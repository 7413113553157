import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from "react-redux";
import { RootState } from 'reducers/index';
import {
  login,
  querySmsCaptcha,
  loginWith2Factor,
  LoginParams,
  TwoFactorLoginParams,
} from 'common/auth/authentication';
import './login.less';
import ProForm, { ModalForm, ProFormDependency, ProFormRadio, ProFormText } from '@ant-design/pro-form';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import moment from 'moment';
import loginImage from '../../assets/images/login-image.png';
import { Button, Form, message, Typography } from 'antd';
import { Storage } from 'utils/storage-util';
import { fetchWecom } from './login.request';
// import { changePassword } from 'pages/user/user/user.reducer';

export interface LoginProps extends RouteComponentProps, StateProps, DispatchProps {
  errorMessage: any;
}

let timeChange: NodeJS.Timeout;

const Login: React.FC<LoginProps> = (props) => {
  const { navigate, login, loginWith2Factor, errorMessage,isAuthenticated, mfaFactor, mfaTemplateToken, phone } = props;
  const [user, setUser] = useState<LoginParams & TwoFactorLoginParams>({});
  const [isVisible, setIsVisible] = useState(false);
  const [displayStyle, setDisplayStyle] = useState(false);
  const [time, setTime] = useState(60);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState('获取验证码');
  const [clientName, setClientName] = useState('玉美商城');
  const [accountClient, setAccountClient] = useState('saber')
  const isPhone = !phone || phone === '';
  const [formRefuse] = Form.useForm();
  const [tenant, setTenant] = useState<'tml' | 'tsg' | 'ttw'>();
  const tenantObj = {
    'tml': '庭马来',
    'tsg': '庭新加坡',
    'ttw': '庭台湾',
  };

  useEffect(() => {
    errorMessage && message.error(errorMessage.split('___')[0])
  },[errorMessage])

  useEffect(() => {
    clearInterval(timeChange);
    return () => clearInterval(timeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeChange]);

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`重新获取(${time})`);
    } else clearTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const clearTime = () => {
    clearInterval(timeChange);
    setBtnDisabled(false);
    setTime(60);
    setBtnContent('获取验证码');
  }

  useEffect(() => {
    console.log(isAuthenticated)
    isAuthenticated && navigate && navigate('/');
  }, [isAuthenticated, navigate])

  // 弹框-短信验证码
  useEffect(() => {
    mfaFactor && setIsVisible(mfaFactor);
  }, [mfaFactor])

  // 发送短信验证码
  const getSmsCaptcha = async () => {
    timeChange = setInterval(() => setTime(t => --t), 1000);
    setBtnDisabled(true);
    querySmsCaptcha(phone).then((res: any) => {
      res && setUser({ ...user, captchaKey: res?.id });
      message.success('验证码已发送成功！');
    }).catch(error => {
      message.warning("验证码发送失败！");
    })
  };


  let [currentDateTime, setDateTime] = useState(moment().format('yyyy-MM-DD HH:mm:ss'));
  const onKeyDown = (e: any) => {
    e.keyCode === 13 && formRefuse.validateFields().then(async value => {
      Storage.session.set("x-tenant", value.tenant);
      delete value.tenant;
      setUser(value);
      await login(value);
      setIsVisible(mfaFactor);
      Storage.session.set("account-client", accountClient);
    });
  };
  useEffect(() => {

    // 这里不直接加载SDK，但你可以检查它是否已加载  
    if ((window as any).WwLogin) {
      fetchCode();
    } else {
      // 可能需要等待SDK加载完成，或者显示加载中的提示  
    }

    const tenant = window.location.href.split('?tenant=')?.[1];
    tenant && ((tenant === 'tml') || (tenant === 'tsg') || (tenant === 'ttw')) && setTenant(tenant);
    tenant && formRefuse.setFieldsValue({
      tenant
    });
    Storage.session.clear();
    let timer = setInterval(() => {
      setDateTime(() => {
        return moment().format('yyyy-MM-DD HH:mm:ss')
      })
    }, 1000);
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    return () => {
      clearInterval(timer);
      window.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCode = () => {
    // fetchWecom().then(e => {
    //   const data = e.data;
    //   console.log(data, e)
    //   // 初始化登录  
    //   new (window as any).WwLogin({
    //     id: "login_container",
    //     login_type: 'CorpApp',
    //     appid: data.appId,
    //     agentid: data.agentId,
    //     // redirect_uri: encodeURIComponent(data.host +'/loginCode?grant_type=wecom&agentid=' + data.agentId),
    //     redirect_uri: encodeURIComponent('https://console-test.merrige.cn' +'/loginCode?grant_type=wecom&agentid=' + data.agentId),
    //     state: "STATE", // 可选  
    //     lang: 'zh_CN' // 可选，自定义样式链接  
    //   });
    // });
    fetchWecom().then(e => {
      console.log(e)
      const data = e.data.data
      setClientName(data.clientName)
      Storage.session.set("fetchWecom", data);
      new (window as any).ww.createWWLoginPanel({
        el: '#login_container',
        params: {
          login_type: 'CorpApp',
          appid: data.appId,
          agentid: data.agentId,
          redirect_uri: 'https://uc.merrige.cn/api/' + data.redirectUris,
          state: 'loginState',
          redirect_type: 'top'
        },
        onCheckWeComLogin(data: any) {
          console.log(data)
        },
        onLoginSuccess(data: any) {
          console.log(data, '----------------')
        },
        onLoginFail(err: any) {
          console.log(err)
        }
      })
    });
  };

  return (
    <div className="login-container">
      <div className="login-main">
        <div className="logo-con">
          <p className="time">{currentDateTime}</p>
          <img src={loginImage} alt="" />
        </div>
        <div className="form-con">
          {!tenant && <h3 className='form-con-title'>欢迎登录{clientName}</h3>}
          {tenant && <h3 className='form-con-title'>欢迎登录{tenantObj[tenant]}商城</h3>}
          <ProForm
            onFinish={async (value) => {
              await Storage.session.set("x-tenant", value.tenant);
              delete value.tenant;
              setUser(value);
              await login(value);
              setIsVisible(mfaFactor);
              Storage.session.set("account-client", accountClient);

            }}
            form={formRefuse}
            submitter={{
              searchConfig: { submitText: '登录' },
              render: (_, dom) => dom.pop(),
              submitButtonProps: {
                size: 'large',
                style: { width: '100%', display: displayStyle ? 'none' : 'block' },
              },
            }}
          >
            <ProFormRadio.Group
              name="type"
              initialValue='code'
              options={[
                {
                  label: '扫码登陆',
                  value: 'code'
                },
                {
                  label: '账号登录',
                  value: 'number',
                }
              ]}
            />
            <ProFormDependency name={['type']}>
              {({ type }) => {
                (type === 'code') ? setDisplayStyle(true) : setDisplayStyle(false);
                return type === 'number' ? <>
                  <ProFormText
                    fieldProps={{ size: 'large', prefix: <UserOutlined /> }}
                    name="username"
                    placeholder="请输入账号"
                    rules={[{ required: true, message: '请输入账号!', }]} />
                  <ProFormText.Password
                    fieldProps={{ size: 'large', prefix: <LockOutlined /> }}
                    name="password"
                    placeholder="请输入密码"
                    rules={[{ required: true, message: '请输入密码!', }]} />
                  <ProFormRadio.Group
                    name="clientId"
                    initialValue='saber'
                    hidden={tenant ? true : false}
                    fieldProps={{
                      onChange: (e) => setAccountClient(e.target.value)
                    }}
                    options={[
                      {
                        label: '运营平台',
                        value: 'saber',
                      }, {
                        label: '商户',
                        value: 'seller'
                      }
                    ]}
                  />
                </> : <></>
              }}
            </ProFormDependency>
            <div id="login_container" style={{ width: !displayStyle ? '0px' : '500px', height: !displayStyle ? '0px' : '400px', overflow: 'hidden' }}></div>
          </ProForm>
        </div>
      </div>
      <ModalForm<{
        phoneNumber: string,
        captchaCode: string
      }>
        width={500}
        title='短信验证'
        visible={isVisible}
        modalProps={{
          onCancel: () => {
            setIsVisible(false);
            clearTime();
          }
        }}
        onFinish={async (res) => {
          if (!!phone && phone !== '') {
            const params = { ...user, ...res, phoneNumber: phone, token: mfaTemplateToken }
            loginWith2Factor(params);
          }
          return true;
        }}
      >
        {isPhone && <Typography.Text type='danger'>未绑定手机号，请联系管理员！</Typography.Text>}
        <div className="login-phone-code">
          <ProFormText width="sm" label='手机号' name='phoneNumber'
            placeholder='请绑定手机号' disabled={true} fieldProps={{ value: phone }} />
          <Button type='primary' onClick={getSmsCaptcha} disabled={isPhone || btnDisabled}>{btnContent}</Button>
        </div>
        <ProFormText width="sm" label='短信验证码' name='captchaCode'
          placeholder='请输入短信验证码' disabled={isPhone} />
      </ModalForm>
      {/* <ModalForm
        title={errorTitle}
        visible={changePasswordDialogVisible}
        onFinish={async (res) => {
          changePassword({ ...res }).then(e => {
            message.success('修改成功，请登录！');
          })
          return true;
        }}
        form={form}
        onVisibleChange={(e) => {
          setChangePasswordDialogVisible(e);
          form.resetFields();
          form.setFieldsValue({
            account: formRefuse.getFieldValue('username')
          });
        }}
      >
        <ProForm.Item
          label="登录账号"
          name={'account'}
        >
          <Input disabled minLength={6} maxLength={15} />
        </ProForm.Item>
        <ProForm.Item
          label="旧密码"
          name="oldPassword"
          rules={[{ required: true, message: '旧密码不能为空。' }]}
          hasFeedback
        >
          <Input.Password placeholder="旧密码" minLength={6} maxLength={15} />
        </ProForm.Item>
        <ProForm.Item
          label="新密码"
          name="newPassword"
          rules={[{
            required: true, message: '必须包含大小写字母、特殊字符和数字的组合，长度在 8-16 之间',
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?_-]).{8,16}$/
          }]}
          hasFeedback
        >
          <Input.Password placeholder="新密码" minLength={6} maxLength={15} />
        </ProForm.Item>
        <ProForm.Item
          label="重复新密码"
          name="confirmPassword"
          dependencies={['newPassword']}
          hasFeedback
          rules={[{
            required: true, message: '请再输入一次新密码。'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject('两次输入的密码不匹配');
            },
          })]}
        >
          <Input.Password placeholder="再次输入新密码" minLength={6} maxLength={15} />
        </ProForm.Item>
      </ModalForm> */}
    </div>
  );
};

const mapStateToProps = ({ authentication }: RootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  mfaFactor: authentication.mfaFactor,
  mfaTemplateToken: authentication.mfaTemplateToken,
  phone: authentication.phone,
  errorMessage: authentication.errorMessage
});

const mapDispatchToProps = {
  login,
  loginWith2Factor
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
